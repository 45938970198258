import { ValidationErrors } from '@angular/forms';
import { isBefore } from 'date-fns/isBefore';

import {
  RoomBookingFormGroupModel,
  RoomBookingFormValueModel,
} from '@bookly/shared';

export function eventTimeValidator() {
  return (
    formGroup: RoomBookingFormGroupModel<RoomBookingFormValueModel>
  ): ValidationErrors | null => {
    const startDate = formGroup.controls.startDate;
    const endDate = formGroup.controls.endDate;
    const startHour = formGroup.controls.startHour;
    const endHour = formGroup.controls.endHour;

    const hoursStart = new Date(
      startDate.value.toDateString() + ' ' + startHour.value?.toTimeString()
    );
    const hoursEnd = new Date(
      endDate.value?.toDateString() + ' ' + endHour.value?.toTimeString()
    );

    const isHourBefore = isBefore(hoursStart, hoursEnd);
    const isStartInThePast = isBefore(hoursStart, new Date());

    if (isStartInThePast) {
      startHour.setErrors({ error: true });

      return { timeFromPast: true };
    }

    if (!isHourBefore) {
      startHour.setErrors({ error: true });
      endHour.setErrors({ error: true });
    } else {
      startHour.setErrors(null);
      endHour.setErrors(null);
    }

    return !isHourBefore ? { timeNotValid: true } : null;
  };
}

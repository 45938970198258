<div class="messages-container">
  @if (bookingRequestStatus !== BookingStatusEnum.Deleted) {
    <div class="message-input">
      <rds-form-field labelPosition="none">
        <input
          rdsInput
          [formControl]="messageControl"
          (keyup.enter)="postMessage()"
          placeholder="Type your message" />
      </rds-form-field>
      <button
        rds-primary-button
        size="l"
        [disabled]="messageControl.invalid"
        (click)="postMessage()">
        Post
      </button>
    </div>
  }
  @for (message of messages; track message.messageId) {
    <div class="message">
      <rds-avatar size="l" disableInteractions="true">
        @if (message.sender.photo) {
          <img
            rds-avatar-image
            [ngSrc]="message.sender.photo"
            fill
            alt="user avatar"
            referrerpolicy="no-referrer" />
        } @else {
          <rds-icon rds-avatar-icon namespace="outlined" icon="user"></rds-icon>
        }
      </rds-avatar>
      <div
        class="message-box"
        [ngClass]="!message.readByViewer ? 'message-box-unread' : null">
        <div class="message-header">
          <p class="ui-body-m-bold">
            {{
              message.sender.fullName
                ? message.sender.fullName
                : message.sender.email
            }}
          </p>
          @if (!message.readByViewer) {
            <rds-badge size="l" label="" type="dot"></rds-badge>
          }
        </div>
        <p class="ui-body-s c-600-grey-2">{{ message.createdAt }}</p>
        <p class="ui-body-m-bold">{{ message.message }}</p>
      </div>
    </div>
  } @empty {
    <rds-empty-state alignment="center">
      <rds-empty-state-title> No messages </rds-empty-state-title>
    </rds-empty-state>
  }
</div>

import { ValidationErrors } from '@angular/forms';
import { differenceInMinutes } from 'date-fns';

import {
  RoomBookingFormGroupModel,
  RoomBookingFormValueModel,
  RoomSettingsDurationRangeModel,
} from '@bookly/shared';

export function eventDurationValidator(
  min: RoomSettingsDurationRangeModel,
  max: RoomSettingsDurationRangeModel
) {
  return (
    formGroup: RoomBookingFormGroupModel<RoomBookingFormValueModel>
  ): ValidationErrors | null => {
    const startHour = formGroup.controls.startHour;
    const endHour = formGroup.controls.endHour;

    const hoursStart = new Date(
      formGroup.controls.startDate.value.toDateString() +
        ' ' +
        startHour.value?.toTimeString()
    );
    const hoursEnd = new Date(
      formGroup.controls.endDate.value?.toDateString() +
        ' ' +
        endHour.value?.toTimeString()
    );

    const diffInMinutes = differenceInMinutes(hoursEnd, hoursStart);

    if (durationToMinutes(min) > diffInMinutes) {
      startHour.setErrors({ error: true });
      endHour.setErrors({ error: true });

      return { durationTooShort: true };
    }
    if (durationToMinutes(max) < diffInMinutes) {
      startHour.setErrors({ error: true });
      endHour.setErrors({ error: true });

      return { durationTooLong: true };
    }

    return null;
  };
}

function durationToMinutes(duration: {
  days: number;
  hours: number;
  minutes: number;
}) {
  return duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
}

import { Injectable, inject, signal } from '@angular/core';
import { Observable, catchError, finalize, switchMap, tap } from 'rxjs';

import {
  AuthService,
  LocationModel,
  RoomCalendarEventsModel,
  RoomFlatModel,
  RoomLayoutUploadApiRequestModel,
  RoomLayoutsModel,
  RoomQueryParams,
  RoomSettingApiResponseModel,
  RoomSettingModel,
  RoomSettingsApiRequestModel,
  RoomsApiService,
  RoomsModel,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  readonly #authService = inject(AuthService);
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #roomsSignal = signal<RoomsModel[] | null>(null);
  readonly #roomAvailabilitySignal = signal<RoomCalendarEventsModel[]>([]);
  readonly #roomLayoutsSignal = signal<RoomLayoutsModel[]>([]);
  readonly #isRoomLayoutsLoadingSignal = signal<boolean>(false);
  readonly #isRoomListLoadingSignal = signal<boolean>(false);
  readonly #isRoomDetailsLoadingSignal = signal<boolean>(false);
  readonly #locationsSignal = signal<LocationModel[]>([]);
  readonly #isLocationsLoadingSignal = signal<boolean>(false);
  readonly #flatRoomsSignal = signal<RoomFlatModel[]>([]);
  readonly #isFlatRoomsLoadingSignal = signal<boolean>(false);

  get rooms() {
    return this.#roomsSignal.asReadonly();
  }

  get roomLayouts() {
    return this.#roomLayoutsSignal.asReadonly();
  }

  get roomAvailability() {
    return this.#roomAvailabilitySignal.asReadonly();
  }

  get isRoomListLoading() {
    return this.#isRoomListLoadingSignal.asReadonly();
  }

  get isRoomDetailsLoading() {
    return this.#isRoomDetailsLoadingSignal.asReadonly();
  }

  get isRoomLayoutsLoading() {
    return this.#isRoomLayoutsLoadingSignal.asReadonly();
  }

  get locations() {
    return this.#locationsSignal.asReadonly();
  }

  get isLocationsLoading() {
    return this.#isLocationsLoadingSignal.asReadonly();
  }

  get flatRooms() {
    return this.#flatRoomsSignal.asReadonly();
  }

  get isFlatRoomsLoading() {
    return this.#isFlatRoomsLoadingSignal.asReadonly();
  }

  public getRooms() {
    this.#isRoomListLoadingSignal.set(true);
    return this.#roomsApiService.getRoomsByEmail().pipe(
      switchMap(roomIds =>
        this.#roomsApiService.getRoomsByRoomIds(roomIds.roomDexIds)
      ),
      tap(rooms => this.#roomsSignal.set(rooms)),
      finalize(() => this.#isRoomListLoadingSignal.set(false))
    );
  }

  public getRoomSettings(roomDexId: string): Observable<RoomSettingModel> {
    this.#isRoomDetailsLoadingSignal.set(true);
    return this.#roomsApiService
      .getRoomSettings(roomDexId)
      .pipe(finalize(() => this.#isRoomDetailsLoadingSignal.set(false)));
  }

  public updateRoomSettings(
    roomDexId: string,
    roomSettings: RoomSettingsApiRequestModel
  ): Observable<RoomSettingApiResponseModel> {
    this.#isRoomDetailsLoadingSignal.set(true);
    return this.#roomsApiService
      .updateRoomSettings(roomDexId, roomSettings)
      .pipe(
        finalize(() => {
          this.#isRoomDetailsLoadingSignal.set(false);
        })
      );
  }

  public uploadLayout(
    layout: Omit<RoomLayoutUploadApiRequestModel, 'adminEmail'>
  ) {
    const layoutRequest: RoomLayoutUploadApiRequestModel = {
      ...layout,
      adminEmail: this.#authService.user?.email || '',
    };

    return this.#roomsApiService.uploadLayout(layoutRequest);
  }

  public uploadLayoutFile(fileUrl: string, file: File) {
    return this.#roomsApiService.uploadLayoutFile(fileUrl, file).pipe(
      catchError(err => {
        throw err;
      })
    );
  }

  public deleteLayout(layoutId: string) {
    return this.#roomsApiService.deleteLayout(layoutId);
  }

  public getRoomLayouts(roomDexId: string) {
    this.#isRoomLayoutsLoadingSignal.set(true);
    this.#roomLayoutsSignal.set([]);
    return this.#roomsApiService.getLayouts(roomDexId).pipe(
      tap(roomLayouts => this.#roomLayoutsSignal.set(roomLayouts)),
      finalize(() => this.#isRoomLayoutsLoadingSignal.set(false))
    );
  }

  public getRoomAvailability(
    calendarId: string,
    dateFrom: string,
    dateTo: string
  ) {
    this.#isRoomDetailsLoadingSignal.set(true);
    return this.#roomsApiService
      .getRoomAvailability(calendarId, dateFrom, dateTo)
      .pipe(
        tap(availability => {
          this.#roomAvailabilitySignal.set(availability);
        }),
        finalize(() => this.#isRoomDetailsLoadingSignal.set(false))
      );
  }

  public getLocations() {
    this.#isLocationsLoadingSignal.set(true);
    return this.#roomsApiService.getLocations().pipe(
      tap(locations => this.#locationsSignal.set(locations)),
      finalize(() => this.#isLocationsLoadingSignal.set(false))
    );
  }

  public getFlatRooms(queryParams?: RoomQueryParams) {
    this.#isFlatRoomsLoadingSignal.set(true);
    return this.#roomsApiService.getFlatRooms(queryParams).pipe(
      tap(rooms => this.#flatRoomsSignal.set(rooms)),
      finalize(() => this.#isFlatRoomsLoadingSignal.set(false))
    );
  }
}

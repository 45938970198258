import { EnvironmentModel } from './environment.model';

const apiUrl = 'https://bokly-dev.aws.cloud.roche.com';

export const environment: EnvironmentModel = {
  shared: {
    development: true,
    apiUrl,
    authApi: `${apiUrl}/auth/v1/auth`,
    googleAuthUrl:
      'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount',
    googleClientId:
      '1038809804201-f9ms8l26e90mnnpdgqn6m5ca58md1doq.apps.googleusercontent.com',
    roomsApi: `${apiUrl}/rooms/v1/rooms/`,
    chatMessages: `${apiUrl}/chat/v1/messages/`,
    roomsByIdsApi: `${apiUrl}/rooms/v1/rooms/items`,
    roomAvailabilityApi: `${apiUrl}/freebusy/v1/unavailabilities/`,
    notificationsApi: `${apiUrl}/notifications/v1/notifications/`,
    locationsApi: `${apiUrl}/rooms/v1/filterparams/locations`,
  },
  user: {
    roomBookingApi: `${apiUrl}/roombookingapi/v1/roombookings`,
  },
  admin: {
    roomSettingsApi: `${apiUrl}/rooms/v1/managespaces/`,
    bookingDetailsApi: `${apiUrl}/roombookingadminapi/v1/roombookings/`,
    roomBookingsApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host`,
    approveRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/approve/`,
    rejectRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/reject/`,
    cancelRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/cancel/`,
    getRoomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/rooms/`,
    roomsByEmailApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
    roomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/roomlayouts/`,
    groupsApi: `${apiUrl}/membergroupsmanagement/v1/groups/`,
    membersApi: `${apiUrl}/membergroupsmanagement/v1/members/`,
    groupRoomsApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
    roomsCalendarApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host/calendar`,
  },
};

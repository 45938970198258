export interface RoomBookingsApiResponseModel {
  createTime: string;
  id: number;
  roomDescription: string;
  roomDexId: string;
  roomName: string;
  startTime: string;
  endTime: string;
  eventName: string;
  status: BookingStatusApiResponseEnum;
  requester: {
    email: string;
    fullName: string;
    photo: string;
  };
}

export interface BookingRequestDetailsApiResponseModel {
  id: number;
  eventName: string;
  numberOfPeople: number;
  wbsCode: string;
  costCenter: string;
  roomLayoutUUID: string;
  userEmail: string;
  startTime: string;
  endTime: string;
  createTime: string;
  status: BookingStatusApiResponseEnum;
  requester: Requester;
  services: Service[];
  roomInfo: RoomInfo;
}

export interface Requester {
  email: string;
  fullName: string;
  photo: string;
}

export interface Service {
  id: number;
  serviceType: RoomServicesApiResponseEnum;
}

export interface RoomInfo {
  id: number;
  roomDexId: string;
  roomName: string;
  roomNumber: string;
  roomType: string;
  active: boolean;
  capacity: number;
  manageSpace: boolean;
  automaticApproval: boolean;
  siteName: string;
  buildingName: string;
  buildingNumber: string;
  street: string;
  floorCode: string;
  siteTimeZone: string;
}

export interface BookingRequestCreateApiRequestModel
  extends BookingRequestBaseApiModel {
  roomDexId: string;
}

export interface BookingRequestUpdateApiRequestModel
  extends BookingRequestBaseApiModel {
  id: number;
}

export interface BookingRequestBaseApiModel {
  eventName: string;
  numberOfPeople: number;
  wbsCode: string | null;
  costCenter: string | null;
  roomLayoutUUID: string;
  startTime: string;
  endTime: string;
  services: {
    serviceType: RoomServicesApiResponseEnum;
  }[];
}

export interface ChatMessageApiResponseModel {
  bookingId: number;
  messageId: string;
  createdAt: string;
  sender: {
    email: string;
    fullName: string;
    department: string;
    photo: string;
    rocheLoginId: string;
  };
  recipients: string[];
  readBy: string[];
  readByViewer: boolean;
  message: string;
}

export interface ChatMessageApiRequestModel {
  bookingId: number;
  message: string;
}

export enum RoomServicesApiResponseEnum {
  Tem = 'TEM',
  Catering = 'CATERING',
  Furniture = 'FURNITURE',
  Locker = 'LOCKER',
}

export enum BookingStatusApiResponseEnum {
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED',
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  New = 'NEW',
}

export interface RoomBookingStatusUpdateApiRequestModel {
  id: number;
  message: string;
}

import { ValidationErrors } from '@angular/forms';
import {
  areIntervalsOverlapping,
  differenceInHours,
  endOfDay,
  isSameDay,
  startOfDay,
} from 'date-fns';

import {
  RoomBookingFormGroupModel,
  RoomBookingFormValueModel,
  RoomSettingsBlockingTimeModel,
} from '@bookly/shared';

export function blockingTimeValidator(
  blockingTimes: RoomSettingsBlockingTimeModel[]
) {
  return (
    formGroup: RoomBookingFormGroupModel<RoomBookingFormValueModel>
  ): ValidationErrors | null => {
    const startDate = formGroup.controls.startDate;
    const endDate = formGroup.controls.endDate;
    const startHour = formGroup.controls.startHour;
    const endHour = formGroup.controls.endHour;
    if (startDate.value && endDate.value) {
      const start = new Date(
        startDate.value.toDateString() + ' ' + startHour.value?.toTimeString()
      );
      const end = new Date(
        endDate.value.toDateString() + ' ' + endHour.value?.toTimeString()
      );
      const some = blockingTimes.some(blockingTime =>
        isOverlapping(start, end, blockingTime)
      );
      if (some) {
        startHour.setErrors({ error: true });
        endHour.setErrors({ error: true });
        return { blockingOverlap: true };
      }
    }
    return null;
  };
}

function isOverlapping(
  start: Date,
  end: Date,
  blockingTime: RoomSettingsBlockingTimeModel
): boolean {
  // If difference > 24h then it will always include blockingTime
  if (differenceInHours(start, end) >= 24) {
    return true;
  } else if (isSameDay(start, end)) {
    return areIntervalsOverlapping(
      { start, end },
      {
        start: new Date(start.toDateString() + ' ' + blockingTime.from),
        end: new Date(start.toDateString() + ' ' + blockingTime.to),
      }
    );
  } else {
    return (
      areIntervalsOverlapping(
        { start, end: endOfDay(start) },
        {
          start: new Date(start.toDateString() + ' ' + blockingTime.from),
          end: new Date(start.toDateString() + ' ' + blockingTime.to),
        }
      ) ||
      areIntervalsOverlapping(
        { start: startOfDay(end), end },
        {
          start: new Date(end.toDateString() + ' ' + blockingTime.from),
          end: new Date(end.toDateString() + ' ' + blockingTime.to),
        }
      )
    );
  }
}

<h2 rds-dialog-header>{{ data.header ?? 'Confirm' }}</h2>
<div rds-dialog-content>{{ data.message ?? 'Are you sure?' }}</div>
<div rds-dialog-actions-container>
  <button rds-secondary-button [rds-dialog-close]="false" size="s">
    {{ data.reject ?? 'No' }}
  </button>
  <button rds-primary-button [rds-dialog-close]="true" size="s">
    {{ data.accept ?? 'Yes' }}
  </button>
</div>

import { RdsBadgeColor } from '@rds/angular-components';

import {
  BookingRequestBaseApiModel,
  BookingRequestCreateApiRequestModel,
  BookingRequestDetailsApiResponseModel,
  BookingRequestUpdateApiRequestModel,
  BookingStatusApiResponseEnum,
  ChatMessageApiResponseModel,
  RoomServicesApiResponseEnum,
} from './booking-request-api.model';
import { RoomDetailsModel } from '../../rooms';

export interface RoomBookingModel {
  createTime: string;
  id: number;
  roomDescription: string;
  roomDexId: string;
  roomName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  eventName: string;
  endTime: string;
  status: {
    value: BookingStatusEnum;
    text: string;
    color: RdsBadgeColor;
  };
  requester: {
    email: string;
    fullName: string;
    photo: string;
  };
}

export interface BookingRequestDetailsViewModel {
  bookingDetails: BookingRequestDetailsModel;
  roomDetails: RoomDetailsModel;
}

export interface BookingRequestDetailsModel
  extends Omit<
    BookingRequestDetailsApiResponseModel,
    'startTime' | 'endTime' | 'status' | 'createTime'
  > {
  createTime: Date;
  startTime: Date;
  status: {
    value: BookingStatusEnum;
    text: string;
    color: RdsBadgeColor;
  };
  endTime: Date;
}

export type BookingRequestDetailsUpdateModel =
  BookingRequestUpdateApiRequestModel;

export type BookingRequestCreateModel = BookingRequestCreateApiRequestModel;

export type BookingRequestBaseModel = BookingRequestBaseApiModel;

export type ChatMessageModel = ChatMessageApiResponseModel;

export type RoomServicesEnum = RoomServicesApiResponseEnum;
export const RoomServicesEnum = { ...RoomServicesApiResponseEnum };

export type BookingStatusEnum = BookingStatusApiResponseEnum;
export const BookingStatusEnum = { ...BookingStatusApiResponseEnum };

<h2 rds-dialog-header>All notifications</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  @if (isLoading()) {
    <div class="loader">
      <rds-progress-spinner></rds-progress-spinner>
    </div>
  }
  @for (
    notification of latestNotifications();
    track notification.notificationId
  ) {
    <bsh-notification [notification]="notification"></bsh-notification>
  }
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '@bookly/shared';

import { AuthApiResponse } from './auth.model';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  readonly #http = inject(HttpClient);
  readonly #authUrl = environment.shared.authApi;

  public redirectToGoogle() {
    const googleOauthUrl = environment.shared.googleAuthUrl;
    const googleParams = {
      redirect_uri: window.location.origin,
      prompt: 'consent',
      response_type: 'code',
      client_id: environment.shared.googleClientId,
      scope: 'openid email profile',
      access_type: 'offline',
      service: 'lso',
      o2v: 2,
      theme: 'glif',
      ddm: 0,
      flowName: 'GeneralOAuthFlow',
    };
    const queryParams = new HttpParams({
      fromObject: googleParams,
    });

    window.location.assign(`${googleOauthUrl}?${queryParams.toString()}`);
  }

  public requestToken(requestParams: Params): Observable<AuthApiResponse> {
    const params: Params = {
      ...requestParams,
      redirect_uri: window.location.origin,
    };
    return this.#http.get<AuthApiResponse>(this.#authUrl, {
      params,
    });
  }

  public refreshToken(refreshToken: string): Observable<AuthApiResponse> {
    const params: Params = { refresh_token: refreshToken };
    return this.#http.get<AuthApiResponse>(this.#authUrl, { params });
  }
}

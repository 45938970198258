<main>
  <div class="login-controls">
    <img ngSrc="assets/logo.svg" width="61.57" height="32" alt="roche-logo" />
    <p class="ui-heading-2">Bookly</p>
    <button rds-primary-button (click)="login()" [disabled]="isLoginPending()">
      Sign in with Google
    </button>
    @if (isLoginPending()) {
      <div>
        <rds-progress-bar></rds-progress-bar>
      </div>
    }
  </div>
  <img
    ngSrc="assets/login-bg.png"
    width="1920"
    height="1281"
    class="background"
    priority
    alt="login-background" />
</main>

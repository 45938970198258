import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsDatepickerModule,
  RdsFormFieldModule,
  RdsTooltipModule,
} from '@rds/angular-components';
import { CalendarCommonModule, CalendarWeekModule } from 'angular-calendar';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { startOfWeek } from 'date-fns/startOfWeek';

import {
  BookingRequestDetailsViewModel,
  CalendarEventTypeEnum,
} from '@bookly/shared';

import { RoomsService } from '../../../room-management/services/rooms.service';

@Component({
  selector: 'bad-room-bookings-calendar',
  standalone: true,
  imports: [
    RdsButtonModule,
    CalendarCommonModule,
    RdsFormFieldModule,
    RdsDatepickerModule,
    FormsModule,
    CalendarWeekModule,
    NgClass,
    RdsTooltipModule,
  ],
  templateUrl: './room-bookings-calendar.component.html',
  styleUrl: './room-bookings-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomBookingsCalendarComponent {
  readonly #roomsService = inject(RoomsService);
  protected readonly roomEvents = this.#roomsService.roomAvailability;
  protected bookingRequest: BookingRequestDetailsViewModel | null = null;
  protected viewDate: Date = new Date();
  protected readonly CalendarEventTypeEnum = CalendarEventTypeEnum;

  @Input() set roomBooking(value: BookingRequestDetailsViewModel | null) {
    if (value) {
      this.viewDate = new Date(value.bookingDetails.startTime);
      this.bookingRequest = value;
    }
  }

  protected viewDateChange(calendarView: Date) {
    const weekStart = startOfWeek(calendarView, {
      weekStartsOn: 1,
    });
    const weekEnd = endOfWeek(calendarView, { weekStartsOn: 1 });
    if (this.bookingRequest) {
      this.#roomsService
        .getRoomAvailability(
          this.bookingRequest.roomDetails.calendarId,
          format(weekStart, "yyyy-MM-dd'T'00:00:00'Z'"),
          format(weekEnd, "yyyy-MM-dd'T'23:59:59'Z'")
        )
        .subscribe();
    }
  }
}

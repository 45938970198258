import { Injectable } from '@angular/core';

// eslint-disable-next-line
declare const gtag: any;

export enum GoogleAnalyticsEventName {
  Approve = 'Request Approved',
  Reject = 'Request Rejected',
  Cancel = 'Request Canceled',
  Edit = 'Request Edited',
  MessageSent = 'Request message sent',
}

export enum GoogleAnalyticsEventCategory {
  BookingRequest = 'Booking Request',
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  trackEvent(
    eventName: GoogleAnalyticsEventName,
    eventCategory: GoogleAnalyticsEventCategory,
    eventDetails: string | number
  ) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventName,
      value: `Request ID: ${eventDetails}`,
    });
  }
}

export interface NotificationsApiResponseModel {
  userEmail: string;
  notificationId: number;
  notificationTitle: string;
  notificationContent: string;
  attributes: Attributes;
  status: NotificationStatusEnum;
  sourceId: SourceId;
  sourceService: NotificationSourceTypeEnum;
  createdAt: string;
  expirationTimeEpoch: number;
  emailStatus: string;
  emailSentAt: string;
}

export interface ChatAttributes {
  chatMessage: string;
  roomDexId: string;
  bookingId: number;
  bookingLink: string;
  roomName: string;
  bookingStatus: string;
}

export type Attributes = ChatAttributes & RoomBookingAttributes;

export interface SourceId {
  idName: string;
  idValue: string;
}

export interface RoomBookingAttributes {
  temIncluded: string;
  reason: string;
  address: string;
  cateringIncluded: string;
  timeTo: string;
  roomDexId: string;
  bookingId: number;
  roomName: string;
  timeFrom: string;
  bookingLink: string;
  eventName: string;
  bookingStatus: string;
  furnitureTeamIncluded: string;
  numberOfPeople: number;
}

export enum NotificationStatusEnum {
  Unread = 'UNREAD',
  Read = 'READ',
}

export enum NotificationSourceTypeEnum {
  RoomBooking = 'ROOM_BOOKING',
  Chat = 'CHAT',
}

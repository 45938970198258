import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
class CoreModule {
  static {
    this.ɵfac = function CoreModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();
const RdsThemeLight = 'light';
const RdsThemeDark = 'dark';
const RdsThemes = [RdsThemeLight, RdsThemeDark];
const RdsTypographyList = ['article-display-l', 'article-display-m', 'article-heading-1', 'article-heading-1-bold', 'article-heading-2', 'article-heading-2-bold', 'article-heading-3', 'article-heading-3-bold', 'article-heading-4', 'article-heading-4-bold', 'article-heading-5', 'article-heading-5-bold', 'article-heading-6', 'article-heading-6-bold', 'article-quote-m', 'article-quote-s', 'article-kpi', 'article-lead', 'article-body-m', 'article-body-m-bold', 'article-body-s', 'article-body-s-bold', 'article-caption', 'article-footnote',
// UI
'ui-heading-1', 'ui-heading-1-bold', 'ui-heading-2', 'ui-heading-2-bold', 'ui-heading-3', 'ui-heading-3-bold', 'ui-heading-4', 'ui-heading-4-bold', 'ui-quote', 'ui-body-m', 'ui-body-m-bold', 'ui-body-s', 'ui-body-s-bold', 'ui-label-l', 'ui-label-l-bold', 'ui-label-m', 'ui-label-m-bold', 'ui-label-s', 'ui-overline', 'ui-component-text-m', 'ui-component-text-s'];
const RocheFontList = ['RocheSans-Bold', 'RocheSans-BoldItalic', 'RocheSans-Italic', 'RocheSans-Light', 'RocheSans-LightItalic', 'RocheSans-Medium', 'RocheSans-MediumItalic', 'RocheSans-Regular', 'RocheSansCondensed-Italic', 'RocheSansCondensed-Light', 'RocheSansCondensed-LightItalic', 'RocheSansCondensed-Regular', 'RocheSerif-Bold', 'RocheSerif-BoldItalic', 'RocheSerif-Italic', 'RocheSerif-Light', 'RocheSerif-LightItalic', 'RocheSerif-Regular'];
const RdsFontList = ['roche-sans', 'roche-sans-condensed', 'roche-serif'];

/**
 * Generated bundle index. Do not edit.
 */

export { CoreModule, RdsFontList, RdsThemeDark, RdsThemeLight, RdsThemes, RdsTypographyList, RocheFontList };

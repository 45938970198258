import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { NotificationsApiResponseModel, environment } from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  readonly #http = inject(HttpClient);
  readonly #notificationsUrl = environment.shared.notificationsApi;

  public getNotifications(userEmail: string) {
    return this.#http.get<NotificationsApiResponseModel[]>(
      this.#notificationsUrl + userEmail
    );
  }

  public markNotificationAsRead(userEmail: string, notificationId: number) {
    return this.#http.put(
      this.#notificationsUrl + userEmail + `/${notificationId}`,
      null
    );
  }

  public markAllNotificationAsRead(userEmail: string) {
    return this.#http.put(this.#notificationsUrl + userEmail, null);
  }
}

import { NgClass, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsBadgeComponent,
  RdsButtonModule,
  RdsEmptyStateModule,
  RdsFormFieldModule,
  RdsIconComponent,
} from '@rds/angular-components';

import { BookingStatusEnum, ChatMessageModel } from '@bookly/shared';

@Component({
  selector: 'bad-messages',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsFormFieldModule,
    ReactiveFormsModule,
    RdsButtonModule,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    NgClass,
    RdsBadgeComponent,
    RdsEmptyStateModule,
    RdsIconComponent,
    NgOptimizedImage,
  ],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss',
})
export class MessagesComponent {
  @Input() public bookingRequestStatus: BookingStatusEnum | undefined;
  @Input() public set chatMessages(value: ChatMessageModel[]) {
    this.messages = value.map(message => ({
      ...message,
      createdAt: new Date(message.createdAt).toLocaleString(['en-GB'], {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    }));
  }
  @Output() sendMessage: EventEmitter<string> = new EventEmitter();

  protected messages: ChatMessageModel[] = [];
  protected readonly BookingStatusEnum = BookingStatusEnum;
  protected readonly messageControl = new FormControl('', [
    Validators.required,
  ]);

  protected postMessage() {
    if (this.messageControl.valid && this.messageControl.value) {
      this.sendMessage.emit(this.messageControl.value);
      this.messageControl.patchValue(null);
      this.messageControl.markAsUntouched();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  BookingRequestCreateModel,
  BookingRequestDetailsApiResponseModel,
  BookingRequestDetailsModel,
  BookingRequestUpdateApiRequestModel,
  ChatMessageApiRequestModel,
  ChatMessageApiResponseModel,
  ChatMessageModel,
  RoomBookingModel,
  RoomBookingStatusUpdateApiRequestModel,
  RoomBookingsApiResponseModel,
  environment,
} from '@bookly/shared';

import {
  bookingDetailsConverter,
  roomBookingsConverter,
} from './booking-request.converter';

@Injectable({
  providedIn: 'root',
})
export class BookingRequestApiService {
  readonly #http = inject(HttpClient);
  readonly #bookingDetailsUrl = environment.admin.bookingDetailsApi;
  readonly #roomBookingsUrl = environment.admin.roomBookingsApi;
  readonly #userRoomBookingsUrl = environment.user.roomBookingApi;
  readonly #approveRoomBookingUrl = environment.admin.approveRoomBookingApi;
  readonly #rejectRoomBookingUrl = environment.admin.rejectRoomBookingApi;
  readonly #cancelRoomBookingUrl = environment.admin.cancelRoomBookingApi;
  readonly #chatMessagesUrl = environment.shared.chatMessages;

  // https://bokly-dev.aws.cloud.roche.com/roombookingapi/v1/roombookings
  public getBookingDetails(
    bookingId: number
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .get<BookingRequestDetailsApiResponseModel>(
        this.#bookingDetailsUrl + bookingId
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public getRoomBookings(): Observable<RoomBookingModel[]> {
    return this.#http
      .get<RoomBookingsApiResponseModel[]>(this.#roomBookingsUrl)
      .pipe(map(roomBookings => roomBookingsConverter(roomBookings)));
  }

  public createRoomBooking(
    request: BookingRequestCreateModel
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .post<BookingRequestDetailsApiResponseModel>(
        this.#userRoomBookingsUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public approveRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#approveRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public rejectRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#rejectRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public cancelRoomBooking(request: RoomBookingStatusUpdateApiRequestModel) {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#cancelRoomBookingUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public updateRoomBooking(
    request: BookingRequestUpdateApiRequestModel
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .put<BookingRequestDetailsApiResponseModel>(
        this.#bookingDetailsUrl,
        request
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }

  public getMessages(bookingId: number): Observable<ChatMessageModel[]> {
    return this.#http.get<ChatMessageModel[]>(
      this.#chatMessagesUrl + bookingId
    );
  }

  public markAllMessagesAsRead(bookingId: number) {
    return this.#http.put<ChatMessageModel[]>(
      this.#chatMessagesUrl + bookingId,
      null
    );
  }

  public postMessage(message: ChatMessageApiRequestModel) {
    return this.#http.post<ChatMessageApiResponseModel[]>(
      this.#chatMessagesUrl,
      message
    );
  }
}

import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RdsButtonModule, RdsProgressBarModule } from '@rds/angular-components';

import { AuthService } from '@bookly/shared';

@Component({
  selector: 'bsh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, RdsButtonModule, RdsProgressBarModule],
})
export class LoginComponent {
  readonly #authService = inject(AuthService);

  protected readonly isLoginPending = this.#authService.isLoginPending;

  protected login() {
    this.#authService.login();
  }
}

<h2 rds-dialog-header>Confirmation</h2>
<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  @switch (dialogConfig) {
    @case (BookingRequestActionModalEnum.Confirm) {
      <rds-form-field labelPosition="top">
        <rds-control-label> Approve comment (optional) </rds-control-label>
        <input rdsInput [formControl]="approveControl" />
      </rds-form-field>
    }
    @case (BookingRequestActionModalEnum.Reject) {
      <rds-form-field labelPosition="top">
        <rds-control-label>
          Reason for rejecting the request
        </rds-control-label>
        <input rdsInput [formControl]="rejectControl" />
      </rds-form-field>
    }
    @case (BookingRequestActionModalEnum.Cancel) {
      <rds-form-field labelPosition="top">
        <rds-control-label>
          Reason for canceling the request
        </rds-control-label>
        <input rdsInput [formControl]="cancelControl" />
      </rds-form-field>
    }
  }
</div>
<div rds-dialog-actions-container>
  <button
    rds-primary-button
    size="m"
    (click)="buttonClickHandler(dialogConfig)"
    [disabled]="
      (dialogConfig === BookingRequestActionModalEnum.Reject &&
        !rejectControl.valid) ||
      (dialogConfig === BookingRequestActionModalEnum.Cancel &&
        !cancelControl.valid)
    ">
    Confirm
  </button>
</div>

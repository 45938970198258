import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  RDS_DIALOG_DATA,
  RdsBadgeComponent,
  RdsButtonModule,
  RdsDialogModule,
  RdsDialogService,
  RdsIconComponent,
  RdsProgressSpinnerModule,
  RdsTabComponent,
  RdsTabModule,
} from '@rds/angular-components';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { startOfWeek } from 'date-fns/startOfWeek';
import { filter, switchMap, take } from 'rxjs';

import { BookingStatusEnum, NotificationSourceTypeEnum } from '@bookly/shared';

import { RoomsService } from '../../../room-management/services/rooms.service';
import {
  BookingRequestActionModalComponent,
  BookingRequestActionModalEnum,
} from '../../components/booking-request-action-modal/booking-request-action-modal.component';
import { EventDetailsComponent } from '../../components/event-details/event-details.component';
import { MessagesComponent } from '../../components/messages/messages.component';
import { RoomBookingsCalendarComponent } from '../../components/room-bookings-calendar/room-bookings-calendar.component';
import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bad-booking-request-details',
  templateUrl: './booking-request-details.component.html',
  styleUrls: ['./booking-request-details.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsDialogModule,
    RdsBadgeComponent,
    RdsButtonModule,
    RdsIconComponent,
    RdsTabModule,
    EventDetailsComponent,
    RdsProgressSpinnerModule,
    MessagesComponent,
    RoomBookingsCalendarComponent,
  ],
})
export class BookingRequestDetailsComponent implements OnInit, AfterViewInit {
  readonly #dialogService = inject(RdsDialogService);
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #roomsService = inject(RoomsService);
  readonly #destroyRef = inject(DestroyRef);

  protected readonly bookingData = inject(RDS_DIALOG_DATA) as {
    bookingId: number;
    roomDexId: string;
    setActiveTab?: NotificationSourceTypeEnum;
  };
  protected readonly bookingDetails =
    this.#bookingRequestService.bookingDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly chatMessages = this.#bookingRequestService.chatMessages;
  protected readonly isLoading =
    this.#bookingRequestService.isDetailsDataLoading;
  protected readonly isMessageLoading =
    this.#bookingRequestService.isMessageLoading;
  protected readonly isCalendarLoading =
    this.#roomsService.isRoomDetailsLoading;
  protected readonly BookingStatusEnum = BookingStatusEnum;
  protected readonly BookingRequestActionModalEnum =
    BookingRequestActionModalEnum;
  protected readonly NotificationSourceTypeEnum = NotificationSourceTypeEnum;
  protected readonly isEditMode = signal(false);

  @ViewChild('messagesTab', { static: false })
  protected messagesTab!: RdsTabComponent;

  public ngOnInit() {
    this.#bookingRequestService
      .getBookingRequestData(
        this.bookingData.roomDexId,
        this.bookingData.bookingId
      )
      .pipe(
        switchMap(([booking, room]) => {
          const weekStart = startOfWeek(
            new Date(booking.startTime.toUTCString()),
            {
              weekStartsOn: 1,
            }
          );
          const weekEnd = endOfWeek(booking.startTime.toUTCString(), {
            weekStartsOn: 1,
          });
          return this.#roomsService.getRoomAvailability(
            room.calendarId,
            format(weekStart, "yyyy-MM-dd'T'00:00:00'Z'"),
            format(weekEnd, "yyyy-MM-dd'T'23:59:59'Z'")
          );
        })
      )
      .subscribe();
    this.#bookingRequestService
      .getMessages(this.bookingData.bookingId)
      .subscribe();
    this.#roomsService.getRoomLayouts(this.bookingData.roomDexId).subscribe();
  }

  ngAfterViewInit() {
    this.messagesTab.stateChanges
      .pipe(
        filter(() => this.messagesTab.active),
        filter(() =>
          this.chatMessages().some(message => !message.readByViewer)
        ),
        switchMap(() =>
          this.#bookingRequestService.markAllMessagesAsRead(
            this.bookingData.bookingId
          )
        ),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  protected openModal(action: BookingRequestActionModalEnum) {
    const dialogRef = this.#dialogService.open(
      BookingRequestActionModalComponent,
      {
        size: 's',
        data: action,
      }
    );
    dialogRef
      .afterClosed()
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(dialogData => {
          if (action === BookingRequestActionModalEnum.Confirm) {
            return this.#bookingRequestService.approveBookingRequest(
              this.bookingData.bookingId,
              dialogData.message
            );
          }

          if (action === BookingRequestActionModalEnum.Cancel) {
            return this.#bookingRequestService.cancelBookingRequest(
              this.bookingData.bookingId,
              dialogData.message
            );
          }

          return this.#bookingRequestService.rejectBookingRequest(
            this.bookingData.bookingId,
            dialogData.message
          );
        })
      )
      .subscribe();
  }

  protected setEditMode(isEditMode: boolean) {
    this.isEditMode.set(isEditMode);
  }

  protected postMessage(message: string) {
    this.#bookingRequestService
      .postMessage({
        bookingId: this.bookingData.bookingId,
        message,
      })
      .subscribe();
  }
}
